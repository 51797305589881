.nav_main {
  text-align: center;

  ul {
    display: inline-block;

    li {
      display: inline-block;
      line-height: 75px;
      a {
        font-family: $font-headline-medium;
        letter-spacing: 0.1em;
        padding: 0 13px;
        display: inline-block;
        @include transition(all, 0.2s, ease-out, 0.1s);

        &.active:after {
          height: 50%;
          width: 100%;
          opacity: 1;
          top: 25%;
          left: 0;
          border: 1px solid $color-blue-light;
          background-color: transparent;
        }
        &.active:hover {
          color: #000;
        }

        &:hover:after {
          height: 50%;
          width: 100%;
          opacity: 1;
          top: 25%;
          left: 0;
        }
      }

      &:first-child a {
        @include transition(all, 0.2s, ease-out, 0s);
      }

      &:last-child a {
        @include transition(all, 0.2s, ease-out, 0.2s);
      }
    }
  }
}

.mobile_nav_toggle {
  position: fixed;
  right: 20px;
  top: 20px;
  display: none;
  background: white;
  border: none;
  padding: 2px 6px 3px 6px;
  z-index: 10000;
  &::after {
    background-color: #fff;
  }
}

@media (max-width: $width-tablet-large) {
  .mobile_nav_toggle {
    display: block;
    &.hidden {
      display: none;
    }
  }
  .nav_main {
    position: fixed;
    top: 0;
    background: white;
    display: none;
    width: 100%;
    z-index: 9999;
    left: 0;

    &.visible {
      display: block;
    }
  }
}

@media (max-width: $width-tablet-small) {
  .mobile_nav_toggle {
    right: 7px;
    top: 10px;
  }
}

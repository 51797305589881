@mixin icon-size($size: 24px) {
  width: $size;
  height: $size;
}

@mixin icon-size-w($size: 24px) {
  width: $size;
}

@mixin icon-size-h($size: 24px) {
  height: $size;
}

%icon {
  fill: #fff;
  @include icon-size;
  vertical-align: middle;
}

.icon-15 {
  @extend %icon;
  @include icon-size(15px);
}

.icon-20 {
  @extend %icon;
  @include icon-size(20px);
}

.icon-30 {
  @extend %icon;
  width: 26px;
  height: 30px;
}

.icon-40 {
  @extend %icon;
  @include icon-size(40px);
}

.icon-60 {
  @extend %icon;
  @include icon-size(60px);
}

.icon-dark {
  fill: #000;
}
.icon-grey {
  fill: $color-grey-dark;
}

.ico_plane {
  @include sprite($plane-2x);
  margin-bottom: 10px;
  display: inline-block;
}

.ico_location {
  @include sprite($location-2x);
  display: inline-block;
}

.ico_bus {
  @include sprite($bus-2x);
  display: inline-block;
}

.ico_train {
  @include sprite($train-2x);
  display: inline-block;
}
.ico_waves {
  @include sprite($waves-2x);
  margin-bottom: 10px;
  display: inline-block;
}

%ico_social {
  position: relative;
  margin: 0 $gutter/4;
  &:after {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    @include transition(opacity, 0.2s);
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

.ico_phone {
  @include sprite($phone-2x);
  display: inline-block;
}
.ico_mail {
  @include sprite($mail-2x);
  display: inline-block;
}

/*************************************************

------------------	KEYVISUAL

*************************************************/

.keyvisual {
  position: relative;
  border: 0px 1px 1px 1px solid $color-grey;
}

/*************************************************

------------------	TEASER

*************************************************/

.teaser__row {
  @extend %row;
}

.teaser__col {
  @extend %col2of8;
  display: block;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  border: 1px solid $color-grey;
  margin-top: 0;
  &:hover {
    h1 {
      color: white;
    }
    svg {
      fill: #fff;
    }
  }
}

.teaser__col-mobile {
  display: none;
}

.teaser__col--text {
  background: white;
  @include transition(all, 0.3s, ease-out, 0s);
}

.teaser__col--left {
  background-image: url(../images/teaser_01-foto.jpg);
  @include transition(all, 0.3s, ease-out, 0.2s);
}

.teaser__col--right {
  background-image: url(../images/teaser_02-foto.jpg);
  @include transition(all, 0.3s, ease-out, 0.4s);
}
.teaser__col--map {
  background-image: url(../images/teaser_location.jpg);
  @include transition(all, 0.3s, ease-out, 0.6s);
  h2 {
    font-family: $font-headline-semibold;
  }
}

@media (max-width: $width-tablet-large) {
  .teaser__col {
    width: 50%;
  }
  .teaser__col-mobile {
    display: block;
  }

  .keyvisual .overlay {
    position: relative;
    margin: -20px 0 40px;
    .overlay__text {
      @include vertical-align-reset;
    }
    .overlay__text__content {
      max-width: 100%;
    }
  }
}

@media (max-width: $width-mobile) {
  .teaser__col {
    width: 100%;
  }
  .teaser__col--left,
  .teaser__col--right {
    width: 50%;
  }
}

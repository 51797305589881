/*
	-> COLORS
*/

$color-grey: #e0e0e0;
$color-grey-light: #ececec;
$color-grey-dark: #747474;

$color-blue-light: #62a2b1;
$color-blue-dark: #144f6c;

/*
	-> FONT
*/

$font-headline: "encode_sans_compressedregular";
$font-headline-medium: "encode_sans_compressedmedium";
$font-headline-semibold: "encode_sans_compressedSBd";

$font-copy: "kreonlight";

$base-font-size: 16px;
@function em($px, $base: $base-font-size) {
  @return $px;
  // @return ($px / $base) * 1em;
}

/*
	-> GRID
*/
$width-screen-small: 1280px;
$width-tablet-large: 1024px;
$width-tablet-small: 768px;
$width-mobile: 640px;

$gutter: 10px;
$total-col: 8;

%col {
  float: left;
  padding: 0 $gutter;
}

%row {
  @extend %cf;
  width: 100%;
}

@for $i from 1 through $total-col {
  %col#{$i}of#{$total-col} {
    @extend %col;
    width: percentage(1 / $total-col) * $i;
  }
  %push#{$i}of#{$total-col} {
    padding-left: $gutter;
    margin-left: percentage(1 / $total-col) * $i;
  }
}

/*
	mobile 
*/

@media (max-width: $width-mobile) {
  @for $i from 1 through $total-col {
    .col#{$i}of#{$total-col} {
      width: 100%;
    }
  }
}

.react-calendar {
  color: $color-grey-light;

  &__navigation {
    &__label {
      &:hover::after {
        display: none;
      }
    }
    &__arrow,
    &__label {
      background-color: $color-blue-light !important;
    }
  }
  &__month-view {
    &__weekdays {
      background-color: $color-blue-dark;
      opacity: 0.8;
      padding: 5px 0;
      border-top: 1px solid $color-grey-light;
      border-bottom: 1px solid $color-grey-light;
      &__weekday abbr {
        text-decoration: none;
      }
    }
    &__days {
      background-color: #a6cad3;
      &__day {
        background: $color-grey;
        border-radius: 4px;
        &:disabled:hover::after {
          display: none;
        }
        &:disabled {
          cursor: default;
          background: $color-grey;
        }
      }
    }
  }
  &__tile {
    &--active {
      background: #990000 !important;
      color: $color-grey-light !important;
      &::after {
        display: none !important;
      }
    }
  }
}

.icon-letter__wrapper {
  overflow: hidden;
  position: absolute;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
}

.pinterest {
  position: fixed;
  background: #ffffff;
  width: 100vw;
  min-height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
}

.pinterest__container {
  width: 80%;
  margin: 0 auto;
}

.pinterest__close-button {
  border: 1px solid $color-blue-dark;
  opacity: 0.5;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 15%;
  font-size: 14px;
  color: $color-blue-dark;
  line-height: 1;
  width: 30px;
  height: 30px;
  background: transparent;
  &:hover, &:focus {
      opacity: 1;
      color: $color-blue-dark;
      &::after {
          display: none;
      }
  }
  @media (max-width: $width-tablet-large) {
    right: 10px;
  }
}

.pinterest__title-wrapper {
  display: flex;
  align-items: center;
  margin: 30px 0;
  justify-content: center;
  @media (max-width: $width-tablet-small) {
    flex-direction: column;
    text-align: center;
  }
}

.pinterest__icon {
  border-radius: 50%;
  margin: 0 20px 0 0;
  @media (max-width: $width-tablet-small) {
    margin: 0 0 20px 0;
  }
}

.pinterest__images-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 800px;
  overflow: auto;
}

.pinterest__image-wrapper {
  width: 300px;
  height: 200px;
  overflow: hidden;
  display: inline-block;
  margin: 10px;
}

.pinterest__image {
  transform: none !important;
}

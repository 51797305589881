/*
	VERTICAL ALIGN
*/

@mixin vertical-align {
  position: relative;
  top: 50%;
  @include translateY(-50%);
}

@mixin vertical-align-reset {
  position: relative;
  top: 0;
  @include translateY(0);
}

@mixin translateY($pos: 0) {
  -webkit-transform: translateY($pos);
  -ms-transform: translateY($pos);
  transform: translateY($pos);
}

@mixin translateX($pos: 0) {
  -webkit-transform: translateX($pos);
  -ms-transform: translateX($pos);
  transform: translateX($pos);
}

@mixin flex($jc: null, $ai: null) {
  display: flex;
  align-items: $ai;
  justify-content: $jc;
}

/* BOX SHADOW */

@mixin box-shadow(
  $x: 2px,
  $y: 2px,
  $blur: 5px,
  $color: rgba(0, 0, 0, 0.4),
  $inset: ""
) {
  @if ($inset != "") {
    -webkit-box-shadow: $inset $x $y $blur $color;
    -moz-box-shadow: $inset $x $y $blur $color;
    box-shadow: $inset $x $y $blur $color;
  } @else {
    -webkit-box-shadow: $x $y $blur $color;
    -moz-box-shadow: $x $y $blur $color;
    box-shadow: $x $y $blur $color;
  }
}

/* TRANSITION */

@mixin transition($what: all, $length: 1s, $easing: ease-in-out, $delay: 0s) {
  -moz-transition: $what $length $easing $delay;
  -o-transition: $what $length $easing $delay;
  -webkit-transition: $what $length $easing $delay;
  -ms-transition: $what $length $easing $delay;
  transition: $what $length $easing $delay;
}

@mixin line-after($color: #ffffff) {
  @include vertical-align;
  content: "";
  display: block;
  width: 100px;
  height: 0px;
  background: transparent;
  border-bottom: 2px solid $color;
  margin-left: 10px;
  left: 100%;
  position: absolute;
}

@mixin line-before($color: #ffffff) {
  @include line-after($color);
  margin-left: 0px;
  margin-right: 10px;
  left: auto;
  right: 100%;
}

/* BORDER RADIUS */

@mixin border-radius($radius: 5px) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin animate-steps($name, $time: 5s, $count: infinite, $direction: normal) {
  -webkit-animation: $name $time $count $direction;
  /* Safari 4+ */
  -moz-animation: $name $time $count $direction;
  /* Fx 5+ */
  -o-animation: $name $time $count $direction;
  /* Opera 12+ */
  animation: $name $time $count $direction;
  /* IE 10+, Fx 29+ */
}

/* ROTATE X */

@mixin rotateX($deg: 180deg) {
  -webkit-perspective: 300px;
  -ms-perspective: 300px;
  perspective: 300px;
  -webkit-transform: rotateX($deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform: rotateX($deg);
  -ms-transform-style: preserve-3d;
  transform: rotateX($deg);
  transform-style: preserve-3d;
}

/* ROTATE X */

@mixin rotateZ($deg: 180deg) {
  -webkit-perspective: 300px;
  -ms-perspective: 300px;
  perspective: 300px;
  -webkit-transform: rotateZ($deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform: rotateZ($deg);
  -ms-transform-style: preserve-3d;
  transform: rotateZ($deg);
  transform-style: preserve-3d;
}

@mixin scaleX($scaleX: 0) {
  -moz-transform: scaleX($scaleX);
  -o-transform: scaleX($scaleX);
  -webkit-transform: scaleX($scaleX);
  transform: scaleX($scaleX);
}

@mixin scale($scale: 0) {
  -moz-transform: scale($scale);
  -o-transform: scale($scale);
  -webkit-transform: scale($scale);
  transform: scale($scale);
}

@mixin background-gradient($startColor: #3c3c3c, $endColor: #999999) {
  background-color: $startColor;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from($startColor),
    to($endColor)
  );
  background-image: -webkit-linear-gradient(top, $startColor, $endColor);
  background-image: -moz-linear-gradient(top, $startColor, $endColor);
  background-image: -ms-linear-gradient(top, $startColor, $endColor);
  background-image: -o-linear-gradient(top, $startColor, $endColor);
  background-image: linear-gradient(top, $startColor, $endColor);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}

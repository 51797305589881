$checked-2x: -0px -0px 20px 23px;
$phone-2x: -0px -215px 34px 30px;
$mail-2x: -0px -245px 34px 30px;
$waves-2x: -0px -275px 34px 32px;
$bus-2x: -0px -307px 34px 33px;
$plane-2x: -0px -340px 34px 34px;
$location-2x: -0px -374px 27px 34px;
$train-2x: -0px -408px 24px 34px;
@mixin sprite-width($sprite) {
  width: nth($sprite, 3);
}
@mixin sprite-height($sprite) {
  height: nth($sprite, 4);
}
@function sprite-width($sprite) {
  @return nth($sprite, 3);
}
@function sprite-height($sprite) {
  @return nth($sprite, 4);
}
@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 1);
  $sprite-offset-y: nth($sprite, 2);
  background-position: $sprite-offset-x $sprite-offset-y;
}
@mixin sprite($sprite, $display: block) {
  @include sprite-position($sprite);
  background-repeat: no-repeat;
  overflow: hidden;
  display: $display;
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}
.icon {
  background-image: url(../images/sprite.png);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .icon {
    background-image: url(../images/sprite@2x.png);
    background-size: 34px 442px;
  }
}

.about,.imprint{
	padding:50px 100px 20px 100px;
	text-align:center;
	border:1px solid $color-grey;
	h1{
		font-family:$font-headline-semibold;
		font-size:em(25px);
		color:$color-blue-light;
		padding-bottom:20px;
	}
	p{
		margin:0 auto;
		padding-bottom:50px;
		max-width:800px;
	}
}

@media (max-width: $width-tablet-large){
	.about,.imprint{
		padding: 50px 0;
	}
}

.agb {
    counter-reset: agb-item;
    margin: 30px auto;
  &__item {
      &__text {
          padding-bottom: 0 !important;
      }
      &__title {
        margin: 20px 0;
        font-size: 16px;
      }
  }
  &__footer {
    margin-top: 50px;
  }
}
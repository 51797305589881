*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  width: 100%;
  overflow-x: hidden;
  background: $color-grey;
}

body {
  background: $color-grey;
  font-family: $font-copy;
  padding: 0 $gutter;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  overflow: hidden;
}

header {
  @extend %row;
  background: white;
  position: relative;
  z-index: 50;
}

footer {
  @extend %row;
  height: 75px;
  text-transform: uppercase;
  font-family: $font-headline;
  padding: $gutter 0;
  background: $color-grey-light;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.body {
  flex-grow: 1;
  position: relative;
}
.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1,
h2 {
  font-size: em(18px);
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

h1 {
  font-family: $font-headline;
}

h2 {
  font-family: $font-copy;
}

h3 {
  font-size: em(13px);
}

.headline {
  font-family: $font-headline;
}

p {
  font-family: $font-copy;
  font-size: em(15px);
  line-height: em(25px);
  letter-spacing: 0.1em;
}

.small {
  font-family: $font-copy;
  font-size: em(12px);
  line-height: em(14px);
  letter-spacing: 0.1em;
  &.white {
    color: white;
  }
}

sup {
  vertical-align: top;
  position: relative;
  font-size: 50%;
  top: -0.2em;
}

a {
  color: $color-grey-dark;
  text-decoration: none;
  @include transition(color, 0.2s);
  &:hover {
    color: $color-blue-dark;
  }
}

.page {
  &-appear {
    opacity: 0;
    &-active {
      opacity: 1;
      @include transition(all, 0.5s, ease-out, 0s);
    }
    &-done {
      opacity: 1;
      @include transition(all, 0.5s, ease-out, 0s);
    }
    &-exit {
      opacity: 0;
    }
  }
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

button,
.btn {
  font-family: $font-headline;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  z-index: 10;
  @include transition(all, 0.2s);
  &:after {
    position: absolute;
    content: "";
    width: 0;
    top: 50%;
    height: 0;
    left: 50%;
    background-color: $color-blue-light;
    opacity: 0;
    z-index: -1;
    @include transition(all, 0.2s);
  }
  &:hover {
    color: #fff;
    &:after {
      opacity: 1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

button[aria-label="Zoom in"],
button[aria-label="Zoom out"] {
  &::after {
    display: none;
  }
}

img {
  display: block;
  width: 100%;
  &.lazy-gallery {
    width: auto;
  }
}

%cf:before,
%cf:after {
  content: " ";
  display: table;
}

%cf:after {
  clear: both;
}

%cf {
  *zoom: 1;
}

.light {
  color: #fff;
}

.light-blue {
  color: $color-blue-light;
}

.dark {
  color: $color-blue-dark;
}

.addthis_sharing_toolbox {
  display: inline-block;
  margin-left: 50px;
  margin: 0;
  border-left: none !important;
}

.caption {
  text-align: right;
  position: absolute;
  font-size: 12px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 3000;
  &.topright {
    top: 0;
    right: 0;
  }
  &.bottomright {
    bottom: 0;
    right: 0;
  }
}

@media (max-width: $width-tablet-large) {
  .caption {
    &.bottomright {
      bottom: -40px;
      right: 0;
    }
  }
}

/*************************************************

------------------	HEADER

*************************************************/

.logo-flag {
  background: $color-blue-light;
  width: 220px;
  height: 114px;
  padding: 15px 40px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  svg {
    width: 100px;
    height: 35px;
    margin-bottom: 7px;
  }
  h1 {
    font-size: em(18px);
    letter-spacing: 1.5px;
    font-family: $font-headline-medium;
  }
  h2 {
    letter-spacing: 2px;
    font-size: em(17px);
  }
  h1,
  h2 {
    text-align: center;
  }
}

.logo-flag__wrapper {
  margin: 0 auto;
}

.header__cta {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: $color-blue-dark;
  color: white;
  @include transition(all, 0.4s, ease-out, 0.2s);
  a {
    font-family: $font-headline-semibold;
    letter-spacing: 0.1em;
    color: white;
    padding: 0 50px;
    height: 100%;
    span {
      display: inline-block;
      @include vertical-align;
    }
  }
}

.text_wrapper {
  padding: 50px 50px 40px 50px;
  p {
    margin-bottom: 40px;
  }
}

.wrapper-light {
  background: white;
}

/*************************************************

------------------	FOOTER

*************************************************/

.footer__info {
  @extend %col6of8;
  position: relative;
  height: 100%;
  padding-left: 25px;
  svg,
  p {
    color: $color-grey-dark;
    float: left;
  }
  p {
    @include vertical-align;
    padding-left: 25px;
  }
}

.footer__info__content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.footer__social {
  @extend %col2of8;
  padding: 0;
  position: relative;
  height: 100%;
}

.footer__social__impressum-link {
  margin-right: 20px;
  font-size: 13px;
  &:hover {
    color: #747474;
  }
}

.footer__social__content {
  position: absolute;
  width: 100%;
  height: 100%;
  @include flex(center, center);
  flex-wrap: wrap;
  h3,
  a {
    color: $color-grey-dark;
    font-family: $font-headline-semibold;
    letter-spacing: 0.1em;
  }
  > div {
    border-left: 1px solid $color-grey-dark;
    padding-left: 20px;
    height: 27px;
    @include flex(null, center);
  }
}

/*************************************************

------------------	OVERLAY

*************************************************/

%overlay-centered {
  @include vertical-align;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  @include transition(all, 0.5s, ease-out, 0.5s);
  h1 {
    @include transition(color, 0.2s);
    font-size: em(30px);
    letter-spacing: 0.1em;
    margin-bottom: 5px;
  }
  h2 {
    font-size: em(17px);
    letter-spacing: 0.1em;
  }
}

.overlay--left {
  text-align: left;
}

.overlay__text {
  @extend %overlay-centered;
}

.overlay--left-text {
  padding-left: 30px;
  h1 {
    font-size: em(20px);
    line-height: em(25px);
    font-family: $font-headline-semibold;
    margin-bottom: $gutter * 2;
  }
  h2 {
    font-size: em(14px);
    line-height: em(20px);
    margin-bottom: $gutter;
  }
  svg {
    @include transition(fill, 0.2s);
  }
}

.overlay__text__content {
  padding: 25px 30px 25px 30px;
  display: inline-block;
  max-width: 80%;
}

.overlay__text__content--dark {
  background: rgba(
    red($color-blue-dark),
    green($color-blue-dark),
    blue($color-blue-dark),
    0.8
  );
  @include box-shadow(0, 0, 15px, rgba(255, 255, 255, 0.4));
}

.overlay__text__content--light {
  background: rgba(255, 255, 255, 0.8);
  @include box-shadow(0, 0, 15px, rgba(0, 0, 0, 0.4));
}

.overlay__cta-btn {
  margin-top: -10px;
  padding: 0 30px;
  background: $color-blue-light;
  display: inline-block;
  @include box-shadow(0, 10px, 15px, rgba(255, 255, 255, 0.2));
  &:after {
    background-color: white;
  }
  &:hover svg {
    fill: $color-blue-dark !important;
  }
}

.overlay__cta-btn--small {
  padding: 12px 30px;
  &:after {
    background-color: $color-blue-dark;
  }
}

/*************************************************

------------------	CONTACT FORM

*************************************************/

.contact__form {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: black;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  top: 0;
  left: 0;
  &-enter {
    display: block;
    opacity: 0;
    &-active {
      display: block;
      opacity: 1;
      transition: opacity 300ms, transform 300ms;
    }
    &-done {
      display: block;
      transition: opacity 300ms, transform 300ms;
    }
  }
  &-exit {
    opacity: 1;
    display: block;
    &-active {
      display: block;
      opacity: 0;
      transition: opacity 300ms, transform 300ms;
    }
  }
  form {
    padding: $gutter;
    text-align: center;
  }
  button {
    font-family: $font-headline-semibold;
    color: black;
    font-size: em(14px);
    letter-spacing: 0.1em;
    padding: $gutter;
    background: lighten($color-blue-light, 20%);
    border: none;
    display: inline-block;
    outline: none;
    &:after {
      background: lighten($color-blue-light, 30%);
    }
  }
  .form_close {
    position: absolute;
    top: $gutter;
    right: $gutter;
    color: white;
    background: $color-blue-light;
    &:hover:after {
      background: $color-blue-dark;
    }
  }
}

.contact__form__wrapper {
  width: 500px;
  margin: 0 auto;
  background: $color-grey;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -250px;
  margin-top: -233px;
}

.form__input__wrapper {
  @extend %col4of8;
  padding: $gutter;
  input {
    width: 100%;
    border: none;
    padding: $gutter;
    border: 1px solid $color-grey;
  }
}

.input-error {
  border-color: #990000 !important;
}

.form__textarea__wrapper {
  @extend %col8of8;
  padding: $gutter;
  textarea {
    width: 100%;
    border: none;
    height: 150px;
    padding: $gutter;
    resize: none;
  }
}

.contact__form__wrapper__header {
  background: $color-blue-light;
  padding: 20px 10px 10px 10px;
  text-align: center;
  color: white;
  font-family: encode_sans_compressedregular;
  h1 {
    line-height: 30px;
    margin-bottom: 15px !important;
    font-size: 18px !important;
  }
  p {
    text-transform: uppercase;
    font-family: encode_sans_compressedregular;
  }
}
/*************************************************

------------------	GALLERY

*************************************************/

.row {
  @extend %row;
  position: relative;
}

.carousel .control-dots .dot {
  margin: 10px 15px !important;
  border: 4px solid #fff;
  width: 21px !important;
  height: 21px !important;
  background-color: #fff;
  opacity: 1 !important;
  transition: all 0.2s ease-in-out 0s !important;
  box-shadow: 0 0 15px rgb(0 0 0 / 90%) !important;
  @media (max-width: $width-mobile) {
    width: 15px !important;
    height: 15px !important;
    margin: 10px 10px !important;
    border: 3px solid #fff;
  }
  &.selected,
  &:hover {
    background-color: #62a2b1;
  }
}

.modal {
  &-enter {
    transform: scale(0);
  }
  &-enter-active {
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  &-exit {
    transform: scale(1);
  }
  &-exit-active {
    transform: scale(0);
    transition: opacity 300ms, transform 300ms;
  }
}

.gallery_wrapper {
  .gallery {
    overflow: hidden;
    ul {
      li {
        div {
          overflow: hidden;
        }
        overflow: hidden;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.fullsize_wrapper {
  @extend %col6of8;
  @extend %push2of8;
  padding: 0;
  position: relative;
  height: 800px;
  @media (max-width: $width-tablet-large) {
    height: auto;
  }
  & .lazy-gallery {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    @media (min-width: $width-tablet-large) {
      height: 800px;
      width: 1185px !important;
    }
    @media (max-width: $width-tablet-large) {
      width: 100%;
      height: auto;
    }
  }
}
.carousel .thumbs-wrapper {
  display: none;
}

.description_wrapper {
  @extend %col2of8;
  min-height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  text-align: center;
  padding: 90px 10px 10px 0;
  position: absolute;
  background: white;
  h1,
  h2 {
    margin-bottom: 20px;
    text-transform: none;
    color: $color-blue-dark;
  }
  h1 {
    margin-bottom: 40px;
    font-family: $font-copy;
    font-size: em(22px);
  }
  h2 {
    font-family: $font-headline-semibold;
    font-size: em(16px);
  }
}

.gallery nav.gallery_dotted_nav {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  &.visible {
    display: block;
  }
  ul {
    @extend %cf;
    display: inline-block;
    padding: 0 $gutter;
    li {
      position: relative;
      width: 21px;
      height: 21px;
      float: left;
      margin: 10px 15px;
      @include border-radius(20px);
      @include box-shadow(0, 0, 15px, rgba(0, 0, 0, 0.9));
      button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 4px solid white;
        @include border-radius(20px);
        background-color: white;
        &:hover {
          background-color: #62a2b1;
        }
        &.active {
          background-color: #62a2b1;
        }
        &:after {
          display: none;
        }
      }
    }
  }
}

.mapbox {
  height: calc(100vh - 114px);
  position: relative;
  @media (max-width: $width-tablet-small) {
    height: calc(60vh);
  }
  #map {
    height: 100%;
    max-height: 100%;
  }
  .leaflet-left .leaflet-control {
    margin-top: 50px;
  }
  .leaflet-control-zoom {
    left: -23px;
    margin-top: 30px;
  }
}

.anim_scroll {
  &-enter {
    @include translateY(50px);
    opacity: 0;
    &-active {
      transform: translateY(0);
      opacity: 1;
      @include transition(all, 0.5s, ease-out, 0s);
    }
    &-done {
      transform: translateY(0);
      opacity: 1;
      @include transition(all, 0.5s, ease-out, 0s);
    }
  }
  &-exit {
    @include translateY(0);
    opacity: 1;
    &-active {
      transform: translateY(50px);
      opacity: 0;
      @include transition(all, 0.5s, ease-out, 0s);
    }
    &-done {
      transform: translateY(50px);
      opacity: 0;
      @include transition(all, 0.5s, ease-out, 0s);
    }
  }
}

@media (max-width: $width-tablet-large) {
  header {
    z-index: 5000;
  }
  .header__cta {
    display: none;
  }
  .logo-flag {
    position: relative;
    width: 100%;
    padding: 20px 40px 10px 40px;
    display: inline-block;
  }
  .description_wrapper {
    width: 37.5%;
    padding: 90px 0;
    h1,
    h2 {
      margin-bottom: 10px;
      font-size: em(16px);
    }
    h1 {
      margin-bottom: 30px;
      font-size: em(20px);
    }
  }
  .teaser--text {
    padding: 40px 40px 0px 40px;
  }
  .text_wrapper {
    padding: 0;
  }
  footer {
    height: auto;
  }
  .footer__info {
    width: 100%;
    padding: 0;
    p {
      @include vertical-align-reset;
    }
  }
  .footer__info__content {
    position: relative;
    text-align: center;
    svg,
    p {
      float: none;
    }
  }
  .footer__social {
    width: 100%;
    margin: 40px 0;
    text-align: center;
  }
  .footer__social__content {
    > div {
      border: none;
    }
  }
  .contact__form__wrapper {
    width: 100%;
    margin-left: 0;
    left: 0;
  }
}

@media (max-width: $width-tablet-small) {
  body {
    padding: 0;
  }
  .overlay {
    h1 {
      font-size: em(22px);
    }
    h2 {
      font-size: em(15px);
    }
  }
}

/*************************************************

------------------	Cookies banner

*************************************************/

.CookieConsent {
  background: #ffffff !important;
  font-weight: 500;
  width: 70% !important;
  display: block !important;
  margin: 20px;
  color: #144f6c !important;
  padding: 20px;
}

.cookies-title {
  margin: 0 0 30px;
  font-weight: 700;
}

.cookies-description {
  line-height: 2;
  margin-bottom: 20px;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
  &__link {
    border: none;
    border-bottom: 4px solid $color-blue-dark;
    background-color: inherit;
    padding: 0;
    min-height: 25px;
    &::after {
      display: none;
    }
    &:hover {
      color: #000000;
    }
  }
  &__agree {
    padding: 10px 20px;
    background-color: $color-blue-dark;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    &:hover::after {
      border-radius: 4px;
    }
  }
}

@media (max-width: $width-mobile) {
  .CookieConsent {
    width: 100% !important;
    margin: 0;
  }
  .button-wrapper {
    &-agree {
      margin-top: 20px;
    }
  }
  .addthis_sharing_toolbox {
    margin: 0;
  }
}

.react-share__ShareButton {
  background-color: transparent;
  border: none;
  padding: 0px;
  font: inherit;
  color: inherit;
  cursor: pointer;
  margin: 0 1px;
  & path {
    transform: translate(-15%, -15%) scale(1.3);
  }
  &:hover {
    transform: translateY(-25%);
  }
}

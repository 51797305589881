/*************************************************

------------------	RENT

*************************************************/

.rent {
  position: relative;
}

.uppercase {
  text-transform: uppercase;
}

.rent__visual {
  background-image: url(../images/rent_visual-foto.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  @extend %col6of8;
  height: 100%;
}

.rent__info {
  @extend %col2of8;
  @extend %push6of8;
  padding: 0;
  background: $color-blue-light;
  float: none;
  text-align: center;

  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzYyYTJiMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0NTcyN2MiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #62a2b1 0%, #45727c 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #62a2b1),
    color-stop(100%, #45727c)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    #62a2b1 0%,
    #45727c 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    #62a2b1 0%,
    #45727c 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #62a2b1 0%, #45727c 100%); /* IE10+ */
  background: linear-gradient(to bottom, #62a2b1 0%, #45727c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62a2b1', endColorstr='#45727c',GradientType=0 ); /* IE6-8 */

  h1 {
    font-family: $font-copy;
    letter-spacing: -0em;
    font-size: em(45px);
  }
  h2 {
    font-family: $font-headline-semibold;
    font-size: em(18px);
  }
  h3 {
    font-family: $font-headline-semibold;
    letter-spacing: 0.15em;
    font-size: em(14px);
  }
  h1,
  h2,
  h3 {
    margin-bottom: 20px;
    text-transform: uppercase;
    color: white;
    &.inactive {
      color: #999;
    }
  }
}

.rent__info__header {
  padding-top: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid white;
}

.rent__info__form {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;

  h2 {
    margin-bottom: 40px;
  }
  button {
    white-space: nowrap;
    font-family: $font-headline-semibold;
    color: black;
    font-size: em(14px);
    letter-spacing: 0.1em;
    padding: $gutter;
    background: lighten($color-blue-light, 20%);
    border: none;
    &:after {
      background: lighten($color-blue-light, 30%);
    }
  }

  .icon-letter {
    width: 130%;
    position: relative;
    left: -15%;
    fill: white;
    height: 70px;
  }
}

.rent__info__form__input-wrapper {
  display: inline-block;
  width: 180px;
  padding: $gutter;
  margin: $gutter;
  position: relative;
  cursor: pointer;
  &__calendar {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;
  }
  background: darken($color-blue-light, 20%);
  border: 1px solid transparent;
  &:hover {
    border: 1px solid $color-blue-light;
  }
  form {
    margin-bottom: 40px;
  }
  input {
    background: none;
    width: 100%;
    padding-left: 30px;
    border: none;
    outline: none;
    color: white;
    letter-spacing: 0.1em;
    // position:relative;
    z-index: 50;
    cursor: pointer;
  }
  input::placeholder {
    color: white;
  }

  svg {
    fill: $color-blue-light;
    z-index: 0;
    position: absolute;
  }
}

form .form_swap_wrapper {
  position: relative;
  z-index: 10;
  margin-bottom: 120px;
  height: 41px;
  @include transition(all, 0.4s, ease-out);
  input {
    color: white;
    background: darken($color-blue-light, 20%);
    border: none;
    width: 80%;
    font-size: em(14px);
    padding: 10px;
    outline: none;
  }

  .inactive {
    position: absolute;
    top: 0;
    margin: 0;
    line-height: 50px;
    width: 100%;
    @include transition(all, 0s, ease-out, 0.2s);
  }
  .active {
    @include rotateX(180deg);
    @include transition(all, 0s, ease-out, 0.2s);
  }
}

form.inactive .form_swap_wrapper {
  @include rotateX(0deg);
  .inactive {
    opacity: 1;
  }
  .active {
    opacity: 0;
  }
}
form.active .form_swap_wrapper {
  @include rotateX(180deg);
  .inactive {
    opacity: 0;
  }
  .active {
    opacity: 1;
  }
}

/*************************************************

------------------	TEASER

*************************************************/

.teaser__row {
  @extend %row;
}

.teaser__col--double {
  @extend %col4of8;
}

.teaser--text {
  background-image: none;
}
.teaser__col--description {
  background: $color-blue-dark;
  position: relative;
  padding: $gutter * 2;
  img {
    width: 100%;
    height: 100%;
  }
  h1 {
    font-size: em(24px);
    position: absolute;
    top: 0;
    height: 100%;
    color: white;
    font-family: $font-headline-semibold;
    span {
      @include vertical-align;
      position: absolute;
    }
    // width:100%;
    // text-align:center;
  }
  &:after {
    content: "";
    position: absolute;
    right: -15px;
    top: 50%;
    margin-top: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 15px;
    border-color: transparent transparent transparent $color-blue-dark;
  }
}

.teaser__col--description--image {
  padding: $gutter;
  background-image: url(../images/teaser_03-foto.jpg);
}
.teaser__col--location {
  background: $color-blue-light;
  position: relative;
  h1 {
    font-family: $font-headline-semibold;
    font-size: em(24px);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    color: white;
    width: 100%;
    text-align: center;
    span {
      @include vertical-align;
      width: 100%;
      position: absolute;
      left: 0;
    }
  }

  &:after {
    z-index: 10;
    content: "";
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 0 15px;
    border-color: $color-blue-light transparent transparent transparent;
  }
}

.teaser__col--location--image {
  background-image: url(../images/teaser_04-foto.jpg);
}

.teaser__col--location--contact {
  background-color: $color-blue-dark;
  text-align: center;
  .icon {
    margin-bottom: 50px;
  }
  h1,
  h2 {
    color: white;
  }
  h1 {
    font-family: $font-copy;
    font-size: em(30px);
    margin-bottom: 30px;
  }
  h2 {
    font-family: $font-headline-semibold;
    margin-bottom: 25px;
    font-size: em(14px);
    line-height: 24px;
  }
  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .vertical-wrapper {
      @include vertical-align;
    }
  }
}
.teaser__col--contact {
  padding-top: 60px;
  background: white;
  height: 810px;
  ul.checked {
    padding-left: 40px;
    li {
      line-height: 1.5;
      padding: 10px 0 10px 0;
      position: relative;
      &:before {
        content: "";
        @extend .icon;
        @include sprite($checked-2x);
        display: inline-block;
        position: absolute;
        left: -25px;
        top: 7px;
      }
    }
  }
  ul.icons {
    text-align: center;
    li {
      padding: 40px 0;
    }
  }
}
.teaser__col--contact--image {
  background-image: url(../images/teaser_06-foto.jpg);
}

@media (max-width: $width-screen-small) {
  .teaser__col--location--contact {
    .icon {
      margin-bottom: 30px;
    }
    h2,
    h1 {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: $width-tablet-large) {
  .rent__visual {
    width: 100%;
    top: 0;
    margin-top: -60%;
    background-position: center top;
    background-size: contain;
  }

  .rent__info {
    position: relative;
    width: 100%;
    margin-left: 0;
    margin-top: 60%;
    .icon-letter {
      height: 80px;
    }
  }
  .description_wrapper {
    position: relative;
    width: 100%;
    border-top: 2px solid $color-grey-light;
    float: none;
  }
  .fullsize_wrapper {
    width: 100%;
    margin-left: 0;
    float: none;
  }
  .teaser__col--double {
    width: 100%;
    float: none;
  }
  .teaser__col--description {
    width: 100%;
    float: none;
    padding-left: 40px;
    img {
      width: 10%;
      height: 5%;
    }
    &:after {
      display: none;
    }
  }
  .teaser__col--location {
    display: none;
  }
  .teaser__col--description--image {
    display: none;
  }

  .teaser__col--location--image {
    display: none;
  }

  .teaser__col--location--contact {
    display: none;
  }
  .teaser__col--contact--image {
    display: none;
  }
  .teaser__col-mobile {
    margin-top: 0;
    display: block;
  }
}
@media (max-width: $width-tablet-small) {
  .rent__info {
    h1 {
      font-size: 34px;
    }
  }
  .teaser__col {
    border: none;
  }
  .teaser__col--location--contact {
    a,
    a.active,
    a.visited {
      color: white;
    }
    h1 {
      font-size: 24px;
    }
  }
  .teaser__col--contact {
    height: auto;
    &.teaser__col--contact--icons {
      padding-top: 0px;
      padding-bottom: 60px;
    }
  }
}
